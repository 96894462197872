// extracted by mini-css-extract-plugin
export var faqBg = "Faqs2-module--faqBg--0bc23";
export var faqButton = "Faqs2-module--faqButton--5e66d";
export var faqsNum = "Faqs2-module--faqsNum--44993";
export var faqsSub = "Faqs2-module--faqsSub--2f843";
export var head = "Faqs2-module--head--b1ec9";
export var quesColor = "Faqs2-module--quesColor--4b2cd";
export var quesColorSelected = "Faqs2-module--quesColorSelected--49a56";
export var section = "Faqs2-module--section--b0065";
export var serial = "Faqs2-module--serial--98a5e";
export var serial2 = "Faqs2-module--serial2--88c80";
export var textColor = "Faqs2-module--textColor--e10b6";